export const environment = {
  production: false,
  apiUrl: 'https://staging.api.plataformax8.com.br/api/v1/',
  mercadoLivreAppId: '6164711367121973',
  shopeePartnerId: "2008103",
  shopeeRedirectUrl: "https://staging.app.plataformax8.com.br/saleschannels",
  sheinRedirectUrl: "aHR0cHM6Ly9zdGFnaW5nLmFwcC5wbGF0YWZvcm1heDguY29tLmJyL3NhbGVzY2hhbm5lbHMvU0hFSU4=",
  sheinPartnerId: "10ED952E7000185DF14B563272F5E",
};

